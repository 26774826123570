import React from 'react';
import { Button } from './ui/button';

const lightenColor = (color, amount) => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
};

const CountButton = React.memo(({ label, count, onClick, color, keyboardKey }) => (
  <Button 
    onClick={onClick} 
    className="h-full w-full text-xl sm:text-2xl md:text-3xl relative rounded-lg shadow-md transition-all hover:shadow-lg flex flex-col justify-center items-center p-2"
    style={{ background: `linear-gradient(45deg, ${color}, ${lightenColor(color, 0.3)})`, color: 'white' }}
    aria-label={`${label}: ${count}`}
    title={`Press ${keyboardKey} to increment`}
  >
    <div className="absolute top-2 left-2 text-base sm:text-lg md:text-xl bg-black bg-opacity-50 px-2 py-1 rounded">{keyboardKey}</div>
    <div className="font-bold text-center">{label}</div>
    <div className="text-3xl sm:text-4xl md:text-5xl mt-2">{count}</div>
  </Button>
));

export default CountButton;