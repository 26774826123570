// LandingView.js

import React from 'react';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { useLanguage } from '../hooks/useLanguage';

const LandingView = ({ 
  sampleType, 
  setSampleType, 
  countType,
  setCountType, 
  totalLimit, 
  handleLimitSelection, 
  customCount, 
  handleCustomCountChange, 
  canStartCounting, 
  setStage 
}) => {
  const { t } = useLanguage();

  return (
    <div className="space-y-8">
      <Card>
        <CardHeader>
          <CardTitle>{t('selectSpecimenType')}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <Button 
            onClick={() => { setSampleType('blood'); setCountType(null); }} 
            className={`w-full text-xl py-4 ${sampleType === 'blood' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
          >
            {t('bloodFilm')}
          </Button>
          <Button 
            onClick={() => { setSampleType('marrow'); setCountType(null); }} 
            className={`w-full text-xl py-4 ${sampleType === 'marrow' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
          >
            {t('boneMarrow')}
          </Button>
        </CardContent>
      </Card>

      {sampleType && (
        <Card>
          <CardHeader>
            <CardTitle>{t('selectCountType')}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <Button 
              onClick={() => setCountType('BlastCount')} 
              className={`w-full text-xl py-4 ${countType === 'BlastCount' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
            >
              {t('blastCount')}
            </Button>
            <Button 
              onClick={() => setCountType('Standard')} 
              className={`w-full text-xl py-4 ${countType === 'Standard' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
            >
              {t('standardCount')}
            </Button>
            {sampleType === 'marrow' && (
              <Button 
                onClick={() => setCountType('Extended')} 
                className={`w-full text-xl py-4 ${countType === 'Extended' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
              >
                {t('extendedCount')}
              </Button>
            )}
          </CardContent>
        </Card>
      )}

      {sampleType && countType && (
        <Card>
          <CardHeader>
            <CardTitle>{t('selectCountLimit')}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4 mb-4">
              {[100, 200, 500].map(limit => (
                <Button 
                  key={limit} 
                  onClick={() => handleLimitSelection(limit)} 
                  className={`text-xl py-4 ${totalLimit === limit ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                >
                  {limit}
                </Button>
              ))}
            </div>
            <p className="text-sm text-gray-600 mb-2">{t('orEnterCustomCount')}:</p>
            <Input 
              type="number" 
              value={customCount} 
              onChange={handleCustomCountChange}
              placeholder={t('customCount')}
              className="text-xl p-4 bg-white border-2 border-gray-300"
            />
          </CardContent>
        </Card>
      )}

      <Button 
        onClick={() => setStage('counting')} 
        className="w-full text-xl py-4 bg-gradient-to-r from-purple-500 to-pink-500 hover:from-pink-500 hover:to-purple-500 text-white transition-all duration-300 transform hover:scale-105 rounded-full shadow-lg"
        disabled={!canStartCounting}
      >
        {t('startCounting')}
      </Button>
    </div>
  );
};

export default LandingView;