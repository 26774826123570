// App.js

import React, { useState } from 'react';
import { useHematologyCount } from './hooks/useHematologyCount';
import { useLanguage } from './hooks/useLanguage';
import LandingView from './components/LandingView';
import CountingView from './components/CountingView';
import ResultsView from './components/ResultsView';
import SavedCountsView from './components/SavedCountsView';
import Notification from './components/ui/Notification';
import { Button } from './components/ui/button';

const HematologyCountApp = () => {
  const {
    stage, setStage, sampleType, countType, counts, totalLimit, showResults, showNotification,
    customCount, categories, keyMap, totalCount, chartData,
    setSampleType, setCountType, handleLimitSelection, handleCustomCountChange,
    resetCount, handleCount, canStartCounting, setShowNotification,
    saveCount, loadCount, deleteCount, savedCounts, saveMessage, loadedCountId, isSaved
  } = useHematologyCount();

  const { t, language, setLanguage } = useLanguage();
  const [showSavedCounts, setShowSavedCounts] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  const handleLoadCount = (count) => {
    loadCount(count);
    setShowSavedCounts(false);
  };

  return (
    <div className={`p-4 max-w-4xl mx-auto min-h-screen flex flex-col ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-800'}`} dir={language === 'ar' ? 'rtl' : 'ltr'}>
      <header className="mb-8">
        <h1 className="text-4xl font-bold text-center text-gray-800">{t('title')}</h1>
        <div className="mt-4 flex justify-between items-center">
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            className="p-2 border rounded"
          >
            <option value="en">English</option>
            <option value="es">Español</option>
            <option value="de">Deutsch</option>
            <option value="it">Italiano</option>
            <option value="ar">العربية</option>
          </select>
          <Button
            onClick={() => setShowSavedCounts(!showSavedCounts)}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            {showSavedCounts ? t('hideSavedCounts') : t('showSavedCounts')}
          </Button>
          <button onClick={toggleDarkMode} className="absolute top-4 right-4">
            {darkMode ? '☀️' : '🌙'}
          </button>
        </div>
      </header>
      
      <main className="flex-grow">
        {showSavedCounts ? (
          <SavedCountsView
            savedCounts={savedCounts}
            loadCount={handleLoadCount}
            deleteCount={deleteCount}
          />
        ) : (
          <>
            {stage === 'landing' && (
              <LandingView 
                sampleType={sampleType}
                setSampleType={setSampleType}
                countType={countType}
                setCountType={setCountType}
                totalLimit={totalLimit}
                handleLimitSelection={handleLimitSelection}
                customCount={customCount}
                handleCustomCountChange={handleCustomCountChange}
                canStartCounting={canStartCounting}
                setStage={setStage}
              />
            )}
            {stage === 'counting' && !showResults && (
              <CountingView 
                sampleType={sampleType}
                countType={countType}
                totalCount={totalCount}
                totalLimit={totalLimit}
                categories={categories[`${sampleType}${countType}`]}
                counts={counts}
                handleCount={handleCount}
                keyMap={keyMap[`${sampleType}${countType}`]}
                resetCount={resetCount}
              />
            )}
            {showResults && (
              <ResultsView 
                chartData={chartData}
                counts={counts}
                totalCount={totalCount}
                resetCount={resetCount}
                saveCount={saveCount}
                saveMessage={saveMessage}
                loadedCountId={loadedCountId}
                isSaved={isSaved}
              />
            )}
          </>
        )}
      </main>
      
      <footer className="mt-8 text-center text-gray-600">
        <p>Mohamed Zahran - QEH Birmingham</p>
        <p>&copy; 2024 HaemCount™ {t('allRightsReserved')}</p>
      </footer>

      {showNotification && (
        <Notification 
          message={t('countLimitReached')} 
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
  );
};

export default HematologyCountApp;