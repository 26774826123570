import React from 'react';
import { Button } from './ui/button';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { useLanguage } from '../hooks/useLanguage';

const SavedCountsView = ({ savedCounts, loadCount, deleteCount }) => {
  const { t } = useLanguage();

  if (!savedCounts || savedCounts.length === 0) {
    return <p className="text-center text-xl">{t('noSavedCounts')}</p>;
  }

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">{t('savedCounts')}</h2>
      {savedCounts.map((count) => (
        <Card key={count.id}>
          <CardHeader>
            <CardTitle>{count.name || t(count.sampleType)} - {new Date(count.savedAt).toLocaleString()}</CardTitle>
          </CardHeader>
          <CardContent>
            <p>{t('totalCount')}: {count.totalCount}</p>
            <div className="flex space-x-2 mt-2">
              <Button onClick={() => loadCount(count)} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2">{t('load')}</Button>
              <Button onClick={() => deleteCount(count.id)} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2">{t('delete')}</Button>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default SavedCountsView;