// useHematologyCount.js

import { useState, useCallback, useMemo, useEffect } from 'react';

export const useHematologyCount = () => {
  const [stage, setStage] = useState('landing');
  const [sampleType, setSampleType] = useState(null);
  const [countType, setCountType] = useState(null);
  const [counts, setCounts] = useState({});
  const [totalLimit, setTotalLimit] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [customCount, setCustomCount] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [savedCounts, setSavedCounts] = useState([]);
  const [saveMessage, setSaveMessage] = useState('');
  const [loadedCountId, setLoadedCountId] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  const categories = useMemo(() => ({
    marrowStandard: ['Myeloid', 'Lymphoid', 'Erythroid', 'Plasma Cells', 'Blasts'],
    marrowExtended: [
      'Blasts', 
      'Promyelocytes', 
      'Myelocytes', 
      'Metamyelocytes', 
      'Band cells', 
      'Neutrophils', 
      'Eosinophils', 
      'Basophils', 
      'Lymphocytes', 
      'Monocytes', 
      'Plasma Cells',
      'Erythroid', 
      'NRBC', 
      'Others'
    ],
    marrowBlastCount: ['Blast', 'Non-Blast'],
    bloodStandard: [
      'Blasts',
      'Promyelocytes',
      'Myelocytes',
      'Metamyelocytes',
      'Band cells',
      'Neutrophils',
      'Eosinophils',
      'Basophils',
      'Lymphocytes',
      'Monocytes',
      'Plasma Cells',
      'NRBC',
      'Others'
    ],
    bloodBlastCount: ['Blast', 'Non-Blast']
  }), []);

  const keyMap = useMemo(() => ({
    marrowStandard: { 'q': 0, 'w': 1, 'e': 2, 'r': 3, ' ': 4 },
    marrowExtended: { 
      ' ': 0, 'q': 1, 'w': 2, 'e': 3, 'r': 4, 't': 5, 
      'y': 6, 'u': 7, 'i': 8, 'o': 9, 'p': 10, 'a': 11,
      's': 12, 'd': 13
    },
    marrowBlastCount: { ' ': 0, 'q': 1 },
    bloodStandard: { 
      ' ': 0, 'q': 1, 'w': 2, 'e': 3, 'r': 4, 't': 5, 
      'y': 6, 'u': 7, 'i': 8, 'o': 9, 'p': 10, 'a': 11, 's': 12
    },
    bloodBlastCount: { ' ': 0, 'q': 1 }
  }), []);

  const handleCount = useCallback((category) => {
    setCounts(prev => {
      const newCounts = { ...prev, [category]: (prev[category] || 0) + 1 };
      const newTotal = Object.values(newCounts).reduce((sum, count) => sum + count, 0);
      if (newTotal === totalLimit) {
        setShowNotification(true);
        setShowResults(true);
      }
      return newCounts;
    });
  }, [totalLimit]);

  const totalCount = useMemo(() => Object.values(counts).reduce((sum, count) => sum + count, 0), [counts]);
  const chartData = useMemo(() => Object.entries(counts).map(([name, value]) => ({ name, value })), [counts]);

  const handleLimitSelection = useCallback((limit) => {
    setTotalLimit(limit);
    setCustomCount('');
  }, []);

  const handleCustomCountChange = useCallback((e) => {
    setCustomCount(e.target.value);
    setTotalLimit(parseInt(e.target.value) || null);
  }, []);

  const resetCount = useCallback(() => {
    setCounts({});
    setStage('landing');
    setShowResults(false);
    setShowNotification(false);
    setSampleType(null);
    setCountType(null);
    setTotalLimit(null);
    setCustomCount('');
    setLoadedCountId(null);
    setIsSaved(false);
  }, []);

  const saveCount = useCallback((name = '') => {
    if (!isSaved || (isSaved && name.trim() !== '')) {
      const newSavedCount = {
        id: Date.now(),
        name: name.trim(),
        sampleType,
        countType,
        counts: { ...counts },
        totalCount,
        totalLimit,
        savedAt: new Date().toISOString()
      };
      setSavedCounts(prev => [...prev, newSavedCount]);
      setSaveMessage(isSaved ? 'Count saved as new entry!' : 'Count saved successfully!');
      setIsSaved(true);
      setTimeout(() => setSaveMessage(''), 3000);
    } else {
      setSaveMessage('Cannot save: Count already saved or no new name provided.');
      setTimeout(() => setSaveMessage(''), 3000);
    }
  }, [sampleType, countType, counts, totalCount, totalLimit, isSaved]);

  const loadCount = useCallback((savedCount) => {
    setSampleType(savedCount.sampleType);
    setCountType(savedCount.countType);
    setCounts(savedCount.counts);
    setTotalLimit(savedCount.totalLimit);
    setStage('counting');
    setShowResults(true);
    setLoadedCountId(savedCount.id);
    setIsSaved(true);
    setSaveMessage('Count loaded successfully!');
    setTimeout(() => setSaveMessage(''), 3000);
  }, []);

  const deleteCount = useCallback((id) => {
    setSavedCounts(prev => prev.filter(count => count.id !== id));
    if (id === loadedCountId) {
      setLoadedCountId(null);
      setIsSaved(false);
    }
  }, [loadedCountId]);

  const canStartCounting = sampleType && countType && totalLimit;

  useEffect(() => {
    const handleKeyPress = (event) => {
      const key = event.key.toLowerCase();
      const currentCategories = categories[`${sampleType}${countType}`];
      const currentKeyMap = keyMap[`${sampleType}${countType}`];

      if (currentCategories && !showResults && (key in currentKeyMap)) {
        const index = currentKeyMap[key];
        handleCount(currentCategories[index]);
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [sampleType, countType, showResults, categories, keyMap, handleCount]);

  return {
    stage,
    setStage,
    sampleType,
    setSampleType,
    countType,
    setCountType,
    counts,
    totalLimit,
    showResults,
    setShowResults,
    customCount,
    showNotification,
    setShowNotification,
    categories,
    keyMap,
    handleCount,
    totalCount,
    chartData,
    handleLimitSelection,
    handleCustomCountChange,
    resetCount,
    canStartCounting,
    saveCount,
    loadCount,
    deleteCount,
    savedCounts,
    saveMessage,
    loadedCountId,
    isSaved
  };
};