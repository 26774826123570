// useLanguage.js

import { useState, useContext, createContext } from 'react';

const translations = {
  en: {
    title: "Hematology Differential Count",
    startCounting: "Start Counting",
    reset: "Reset",
    selectSpecimenType: "Select Specimen Type",
    bloodFilm: "Blood Film",
    boneMarrow: "Bone Marrow",
    selectCountType: "Select Count Type",
    blastCount: "Blast Count",
    standardCount: "Standard Count",
    extendedCount: "Extended Count",
    selectCountLimit: "Select Count Limit",
    customCount: "Custom count",
    results: "Results",
    category: "Category",
    count: "Count",
    percentage: "Percentage",
    startNewCount: "Start New Count",
    savedCounts: "Saved Counts",
    load: "Load",
    delete: "Delete",
    save: "Save",
    saved: "Saved",
    totalCount: "Total Count",
    showSavedCounts: "Show Saved Counts",
    hideSavedCounts: "Hide Saved Counts",
    noSavedCounts: "No saved counts available",
    allRightsReserved: "All rights reserved",
    countLimitReached: "Count limit reached!",
    orEnterCustomCount: "Or enter a custom count",
    sampleType: "Sample Type",
    countType: "Count Type",
    optionalSaveName: "Enter a name for this count (optional)",
    pieChart: "Pie Chart",
    barChart: "Bar Chart",
    detailedResults: "Detailed Results",
    countSavedSuccessfully: "Count saved successfully!",
    countLoadedSuccessfully: "Count loaded successfully!",
    'Blasts': 'Blasts',
    'Promyelocytes': 'Promyelocytes',
    'Myelocytes': 'Myelocytes',
    'Metamyelocytes': 'Metamyelocytes',
    'Band cells': 'Band cells',
    'Neutrophils': 'Neutrophils',
    'Eosinophils': 'Eosinophils',
    'Basophils': 'Basophils',
    'Lymphocytes': 'Lymphocytes',
    'Monocytes': 'Monocytes',
    'Erythroid': 'Erythroid',
    'Plasma Cells': 'Plasma Cells',
    'NRBC': 'NRBC',
    'Others': 'Others',
  },
  es: {
    title: "Recuento Diferencial Hematológico",
    startCounting: "Comenzar Recuento",
    reset: "Reiniciar",
    selectSpecimenType: "Seleccionar Tipo de Muestra",
    bloodFilm: "Frotis de Sangre",
    boneMarrow: "Médula Ósea",
    selectCountType: "Seleccionar Tipo de Recuento",
    blastCount: "Recuento de Blastos",
    standardCount: "Recuento Estándar",
    extendedCount: "Recuento Extendido",
    selectCountLimit: "Seleccionar Límite de Recuento",
    customCount: "Recuento personalizado",
    results: "Resultados",
    category: "Categoría",
    count: "Recuento",
    percentage: "Porcentaje",
    startNewCount: "Iniciar Nuevo Recuento",
    savedCounts: "Recuentos Guardados",
    load: "Cargar",
    delete: "Eliminar",
    save: "Guardar",
    saved: "Guardado",
    totalCount: "Recuento Total",
    showSavedCounts: "Mostrar Recuentos Guardados",
    hideSavedCounts: "Ocultar Recuentos Guardados",
    noSavedCounts: "No hay recuentos guardados disponibles",
    allRightsReserved: "Todos los derechos reservados",
    countLimitReached: "¡Límite de recuento alcanzado!",
    orEnterCustomCount: "O ingrese un recuento personalizado",
    sampleType: "Tipo de Muestra",
    countType: "Tipo de Recuento",
    optionalSaveName: "Ingrese un nombre para este recuento (opcional)",
    pieChart: "Gráfico Circular",
    barChart: "Gráfico de Barras",
    detailedResults: "Resultados Detallados",
    countSavedSuccessfully: "¡Recuento guardado exitosamente!",
    countLoadedSuccessfully: "¡Recuento cargado exitosamente!",
    'Blasts': 'Blastos',
    'Promyelocytes': 'Promielocitos',
    'Myelocytes': 'Mielocitos',
    'Metamyelocytes': 'Metamielocitos',
    'Band cells': 'Células de banda',
    'Neutrophils': 'Neutrófilos',
    'Eosinophils': 'Eosinófilos',
    'Basophils': 'Basófilos',
    'Lymphocytes': 'Linfocitos',
    'Monocytes': 'Monocitos',
    'Erythroid': 'Eritrocítico',
    'Plasma Cells': 'Células Plasmáticas',
    'NRBC': 'NRBC',
    'Others': 'Otros',
  },
  de: {
    title: "Hämatologische Differentialzählung",
    startCounting: "Zählung Starten",
    reset: "Zurücksetzen",
    selectSpecimenType: "Probentyp Auswählen",
    bloodFilm: "Blutausstrich",
    boneMarrow: "Knochenmark",
    selectCountType: "Zählungstyp Auswählen",
    blastCount: "Blasten-Zählung",
    standardCount: "Standard-Zählung",
    extendedCount: "Erweiterte Zählung",
    selectCountLimit: "Zähllimit Auswählen",
    customCount: "Benutzerdefinierte Zählung",
    results: "Ergebnisse",
    category: "Kategorie",
    count: "Zählung",
    percentage: "Prozentsatz",
    startNewCount: "Neue Zählung Starten",
    savedCounts: "Gespeicherte Zählungen",
    load: "Laden",
    delete: "Löschen",
    save: "Speichern",
    saved: "Gespeichert",
    totalCount: "Gesamtzählung",
    showSavedCounts: "Gespeicherte Zählungen Anzeigen",
    hideSavedCounts: "Gespeicherte Zählungen Ausblenden",
    noSavedCounts: "Keine gespeicherten Zählungen verfügbar",
    allRightsReserved: "Alle Rechte vorbehalten",
    countLimitReached: "Zähllimit erreicht!",
    orEnterCustomCount: "Oder geben Sie eine benutzerdefinierte Zählung ein",
    sampleType: "Probentyp",
    countType: "Zählungstyp",
    optionalSaveName: "Geben Sie einen Namen für diese Zählung ein (optional)",
    pieChart: "Kreisdiagramm",
    barChart: "Balkendiagramm",
    detailedResults: "Detaillierte Ergebnisse",
    countSavedSuccessfully: "Zählung erfolgreich gespeichert!",
    countLoadedSuccessfully: "Zählung erfolgreich geladen!",
    'Blasts': 'Blasten',
    'Promyelocytes': 'Promyelozyten',
    'Myelocytes': 'Myelozyten',
    'Metamyelocytes': 'Metamyelozyten',
    'Band cells': 'Bänderzellen',
    'Neutrophils': 'Neutrophile',
    'Eosinophils': 'Eosinophile',
    'Basophils': 'Basophile',
    'Lymphocytes': 'Lymphozyten',
    'Monocytes': 'Monocyten',
    'Erythroid': 'Erythrozyten',
    'Plasma Cells': 'Plasmazellen',
    'NRBC': 'NRBC',
    'Others': 'Andere',
  },
  it: {
    title: "Conta Differenziale Ematologica",
    startCounting: "Inizia Conteggio",
    reset: "Azzera",
    selectSpecimenType: "Seleziona Tipo di Campione",
    bloodFilm: "Striscio di Sangue",
    boneMarrow: "Midollo Osseo",
    selectCountType: "Seleziona Tipo di Conteggio",
    blastCount: "Conteggio Blasti",
    standardCount: "Conteggio Standard",
    extendedCount: "Conteggio Esteso",
    selectCountLimit: "Seleziona Limite di Conteggio",
    customCount: "Conteggio personalizzato",
    results: "Risultati",
    category: "Categoria",
    count: "Conteggio",
    percentage: "Percentuale",
    startNewCount: "Inizia Nuovo Conteggio",
    savedCounts: "Conteggi Salvati",
    load: "Carica",
    delete: "Elimina",
    save: "Salva",
    saved: "Salvato",
    totalCount: "Conteggio Totale",
    showSavedCounts: "Mostra Conteggi Salvati",
    hideSavedCounts: "Nascondi Conteggi Salvati",
    noSavedCounts: "Nessun conteggio salvato disponibile",
    allRightsReserved: "Tutti i diritti riservati",
    countLimitReached: "Limite di conteggio raggiunto!",
    orEnterCustomCount: "O inserisci un conteggio personalizzato",
    sampleType: "Tipo di Campione",
    countType: "Tipo di Conteggio",
    optionalSaveName: "Inserisci un nome per questo conteggio (opzionale)",
    pieChart: "Grafico a Torta",
    barChart: "Grafico a Barre",
    detailedResults: "Risultati Dettagliati",
    countSavedSuccessfully: "Conteggio salvato con successo!",
    countLoadedSuccessfully: "Conteggio caricato con successo!",
    'Blasts': 'Blasti',
    'Promyelocytes': 'Promielociti',
    'Myelocytes': 'Mielociti',
    'Metamyelocytes': 'Metamielociti',
    'Band cells': 'Celle a banda',
    'Neutrophils': 'Neutrofili',
    'Eosinophils': 'Eosinofili',
    'Basophils': 'Basofili',
    'Lymphocytes': 'Linfociti',
    'Monocytes': 'Monociti',
    'Erythroid': 'Eritrocitario',
    'Plasma Cells': 'Cellule Plasmatiche',
    'NRBC': 'NRBC',
    'Others': 'Altri',
  },
  ar: {
    title: "العد التفريقي لخلايا الدم",
    startCounting: "بدء العد",
    reset: "إعادة ضبط",
    selectSpecimenType: "اختر نوع العينة",
    bloodFilm: "مسحة دم",
    boneMarrow: "نخاع العظم",
    selectCountType: "اختر نوع العد",
    blastCount: "عد الخلايا البدائية",
    standardCount: "العد القياسي",
    extendedCount: "العد الموسع",
    selectCountLimit: "اختر حد العد",
    customCount: "عد مخصص",
    results: "النتائج",
    category: "الفئة",
    count: "العدد",
    percentage: "النسبة المئوية",
    startNewCount: "بدء عد جديد",
    savedCounts: "العدادات المحفوظة",
    load: "تحميل",
    delete: "حذف",
    save: "حفظ",
    saved: "تم الحفظ",
    totalCount: "العدد الإجمالي",
    showSavedCounts: "عرض العدادات المحفوظة",
    hideSavedCounts: "إخفاء العدادات المحفوظة",
    noSavedCounts: "لا توجد عدادات محفوظة متاحة",
    allRightsReserved: "جميع الحقوق محفوظة",
    countLimitReached: "تم الوصول إلى حد العد!",
    orEnterCustomCount: "أو أدخل عدًا مخصصًا",
    sampleType: "نوع العينة",
    countType: "نوع العد",
    optionalSaveName: "أدخل اسمًا لهذا العد (اختياري)",
    pieChart: "الرسم البياني الدائري",
    barChart: "الرسم البياني الشريطي",
    detailedResults: "النتائج التفصيلية",
    countSavedSuccessfully: "تم حفظ العد بنجاح!",
    countLoadedSuccessfully: "تم تحميل العد بنجاح!",
    'Blasts': 'خلايا بدائية',
    'Promyelocytes': 'برومييلوسيتات',
    'Myelocytes': 'مييلوسيتات',
    'Metamyelocytes': 'ميتامييلوسيتات',
    'Band cells': 'خلايا شريطية',
    'Neutrophils': 'نيوتروفيلات',
    'Eosinophils': 'إيوزينوفيلات',
    'Basophils': 'بازوفيلات',
    'Lymphocytes': 'ليمفوسيتات',
    'Monocytes': 'مونوسيتات',
    'Erythroid': 'خلايا حمراء',
    'Plasma Cells': 'خلايا بلازما',
    'NRBC': 'خلايا غير حمراء',
    'Others': 'آخرى',
  }
};

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const value = {
    language,
    setLanguage,
    t: (key) => translations[language][key] || key,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};