// CountingView.js

import React from 'react';
import { Button } from './ui/button';
import CountButton from './CountButton';
import { useLanguage } from '../hooks/useLanguage';

const COLORS = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8',
  '#F7B801', '#7FDBFF', '#85144b', '#FDCB6E', '#00CEC9',
  '#6C5CE7', '#FD79A8', '#FF9FF3', '#54A0FF'
];

const CountingView = ({ sampleType, countType, totalCount, totalLimit, categories, counts, handleCount, keyMap, resetCount }) => {
  const { t } = useLanguage();

  const getCategoryKey = (category, index) => {
    if (category === 'Blasts') return 'Space';
    return Object.keys(keyMap).find(key => keyMap[key] === index) || '';
  };

  return (
    <>
      <div className="mb-4 p-4 bg-blue-100 rounded-lg">
        <h2 className="text-2xl font-semibold">{t('sampleType')}: {t(sampleType === 'blood' ? 'bloodFilm' : 'boneMarrow')}</h2>
        <h3 className="text-xl mt-2">{t('countType')}: {t(`${countType}Count`)}</h3>
        <p className="text-xl mt-2">{t('totalCount')}: {totalCount} / {totalLimit}</p>
      </div>
      <div className="grid grid-cols-2 gap-2 mb-4" style={{ minHeight: '60vh' }}>
        {categories.map((category, index) => (
          <CountButton
            key={category}
            label={t(category)}
            count={counts[category] || 0}
            onClick={() => handleCount(category)}
            color={COLORS[index % COLORS.length]}
            keyboardKey={getCategoryKey(category, index)}
          />
        ))}
      </div>
      <Button onClick={resetCount} className="w-full text-xl py-4 bg-red-500 hover:bg-red-600 text-white">{t('reset')}</Button>
    </>
  );
};

export default CountingView;