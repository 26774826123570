import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';
import { useLanguage } from '../hooks/useLanguage';

const COLORS = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8',
  '#F7B801', '#7FDBFF', '#85144b', '#FDCB6E', '#00CEC9',
  '#6C5CE7', '#FD79A8'
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value }) => {
  const radius = outerRadius * 1.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={COLORS[index % COLORS.length]} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={COLORS[index % COLORS.length]} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" dominantBaseline="central">
        {`${name} (${value})`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999" fontSize={12} dominantBaseline="central">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const ResultsView = ({ chartData, counts, totalCount, resetCount, saveCount, saveMessage, loadedCountId, isSaved }) => {
  const { t } = useLanguage();
  const [saveName, setSaveName] = useState('');
  const [canSave, setCanSave] = useState(!isSaved);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [highlightedCategory, setHighlightedCategory] = useState(null);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 640);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setCanSave(!isSaved || (isSaved && saveName.trim() !== ''));
  }, [isSaved, saveName]);

  const handleSave = () => {
    if (canSave) {
      saveCount(saveName);
      setSaveName('');
    }
  };

  const sortedChartData = [...chartData].sort((a, b) => b.value - a.value);

  const renderDetailedResultsTable = () => (
    <table className={`w-full text-left text-lg ${isMobile ? 'text-sm' : ''}`}>
      <thead>
        <tr>
          <th className="py-2"></th>
          <th className="py-2">{t('category')}</th>
          <th className="py-2">{t('count')}</th>
          <th className="py-2">{t('percentage')}</th>
        </tr>
      </thead>
      <tbody>
        {sortedChartData.map((entry, index) => (
          <tr 
            key={entry.name}
            className={highlightedCategory === entry.name ? 'bg-gray-100' : ''}
            onMouseEnter={() => setHighlightedCategory(entry.name)}
            onMouseLeave={() => setHighlightedCategory(null)}
          >
            <td className="py-2">
              <div 
                style={{ backgroundColor: COLORS[index % COLORS.length] }} 
                className="w-4 h-4"
              ></div>
            </td>
            <td className="py-2">{t(entry.name)}</td>
            <td className="py-2">{entry.value}</td>
            <td className="py-2">{((entry.value / totalCount) * 100).toFixed(1)}%</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const exportData = (format) => {
    let data;
    if (format === 'csv') {
      data = `Category,Count,Percentage\n${chartData.map(item => `${item.name},${item.value},${((item.value / totalCount) * 100).toFixed(2)}%`).join('\n')}`;
    } else if (format === 'json') {
      data = JSON.stringify(chartData, null, 2);
    }
    const blob = new Blob([data], { type: format === 'csv' ? 'text/csv' : 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `hematology_count_${format}.${format}`;
    a.click();
  };

  return (
    <div className="mt-8 px-4">
      <h2 className="text-3xl font-bold mb-6 text-center">{t('results')}</h2>
      <div className="flex flex-col space-y-12">
        <div className="h-[300px] md:h-[500px] mb-8">
          <h3 className="text-xl font-semibold mb-4">{t('pieChart')}</h3>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={sortedChartData}
                cx="50%"
                cy="50%"
                labelLine={!isMobile}
                label={isMobile ? null : renderCustomizedLabel}
                outerRadius={isMobile ? "60%" : "80%"}
                innerRadius={isMobile ? "30%" : "40%"}
                fill="#8884d8"
                dataKey="value"
              >
                {sortedChartData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={COLORS[index % COLORS.length]} 
                    opacity={highlightedCategory && highlightedCategory !== entry.name ? 0.3 : 1}
                  />
                ))}
              </Pie>
              <Tooltip 
                contentStyle={{ background: 'rgba(255, 255, 255, 0.8)', borderRadius: '10px', border: 'none' }}
                formatter={(value, name) => [`${value} (${((value / totalCount) * 100).toFixed(1)}%)`, name]}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="h-[300px] md:h-[500px] mb-8">
          <h3 className="text-xl font-semibold mb-4">{t('barChart')}</h3>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={sortedChartData} layout={isMobile ? "horizontal" : "vertical"} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              {isMobile ? (
                <>
                  <XAxis dataKey="name" />
                  <YAxis type="number" />
                </>
              ) : (
                <>
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" width={150} />
                </>
              )}
              <Tooltip />
              <Bar dataKey="value">
                {sortedChartData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={COLORS[index % COLORS.length]} 
                    opacity={highlightedCategory && highlightedCategory !== entry.name ? 0.3 : 1}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="mt-8 mb-8">
          <h3 className="text-xl font-semibold mb-4">{t('detailedResults')}</h3>
          <div className="overflow-x-auto">
            {renderDetailedResultsTable()}
          </div>
        </div>
        <div className="mt-4">
          <Button onClick={() => exportData('csv')} className="mr-2">Export CSV</Button>
          <Button onClick={() => exportData('json')}>Export JSON</Button>
        </div>
      </div>
      <div className="flex flex-col space-y-4 mt-8">
        <input
          type="text"
          value={saveName}
          onChange={(e) => setSaveName(e.target.value)}
          placeholder={isSaved ? t('Enter A New Name To Save Again') : t('Save Name (optional)')}
          className="p-2 border rounded"
        />
        <div className="flex space-x-4">
          <Button onClick={resetCount} className="flex-1 text-xl py-4 bg-blue-500 hover:bg-blue-600 text-white">{t('startNewCount')}</Button>
          <Button 
            onClick={handleSave} 
            className="flex-1 text-xl py-4 bg-green-500 hover:bg-green-600 text-white" 
            disabled={!canSave}
          >
            {isSaved ? t('Save As New') : t('Save')}
          </Button>
        </div>
      </div>
      {saveMessage && <p className="text-green-500 text-center mt-4">{saveMessage}</p>}
    </div>
  );
};

export default ResultsView;
